import { Avatar, Hidden, Icon, Grid, IconButton, MenuItem, useMediaQuery, Stack, Typography } from '@mui/material';
import { Box, styled, useTheme } from '@mui/system';
import { MatxMenu, MatxSearchBox } from 'app/components';
import { themeShadows } from 'app/components/MatxTheme/themeColors';
import { NotificationProvider } from 'app/contexts/NotificationContext';
import useAuth from 'app/hooks/useAuth';
import useSettings from 'app/hooks/useSettings';
import { topBarHeight } from 'app/utils/constant';
import React, { useEffect } from 'react';
import HelpIcon from '@mui/icons-material/Help';
import { MatxVerticalNav } from 'app/components';
import { Fragment } from 'react';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { Link, useLocation } from 'react-router-dom';
import NotificationBar from '../../NotificationBar/NotificationBar';
import { Span } from '../../../components/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Setting from '../../../views/simulation/Setting.js';
//import Depot from '../../../views/simulation/depot.js';
import DepotZone from '../../../views/simulation/dpot_zone.js';
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import Tooltip from '@mui/material/Tooltip';
import LabTabs from 'app/views/simulation/Menubar';
import FixedBar from 'app/views/simulation/FixedBar';
import Header from 'app/views/header/header';
import { useThemeContext } from 'app/ThemeProvider';
import ThemeSwitch from './ThemeSwitch';

import MenuIcon from '@mui/icons-material/Menu';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const TopbarRoot = styled('div')(({ theme }) => ({
  top: 0,
  zIndex: 96,
  transition: 'all 0.3s ease',
  boxShadow: themeShadows[8],
  margin: 0,
  height: topBarHeight,
}));

const TopbarContainer = styled(Box)(({ theme }) => ({
  padding: '8px',
  paddingLeft: 10,
  paddingRight: 10,
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: theme.palette.primary.main,
  [theme.breakpoints.down('sm')]: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  [theme.breakpoints.down('xs')]: {
    paddingLeft: 5,
    paddingRight: 5,
  },
}));

const UserMenu = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: 24,
  padding: 4,
  '& span': { margin: '0 8px' },
}));

const StyledItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: 185,
  '& a': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
  },
  '& span': { marginRight: '10px', color: theme.palette.text.primary },
}));

const IconBox = styled('div')(({ theme }) => ({
  display: 'inherit',
  [theme.breakpoints.down('md')]: { display: 'none !important' },
}));

const Layout1Topbar = ({setSideNavOpen}) => {
  const theme = useTheme();
  const { settings, updateSettings } = useSettings();
  const { logout, user } = useAuth();



  useEffect(() => {
    const intervalId = setInterval(() => {
      const loginTimestamp = localStorage.getItem('loginTimestamp');

      if (loginTimestamp !== undefined && loginTimestamp !== null) {
        const currentTime = new Date();
        const loginTime = new Date(loginTimestamp); // Convert the stored timestamp back to a Date object

        // Calculate the difference in minutes
        const timeDifference = (currentTime - loginTime) / (1000 * 60); // Difference in minutes

        if (timeDifference > 120) {
          // Add your logic here, e.g., log out the user, reset session, etc.
          logout();
        }
      }
    }, 10000); // 10 seconds in milliseconds

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array to only run once after component mounts



  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { darkTheme } = useThemeContext();

  const updateSidebarMode = (sidebarSettings) => {
    updateSettings({
      layout1Settings: { leftSidebar: { ...sidebarSettings } },
    });
  };

  const handleSidebarToggle = () => {
    let { layout1Settings } = settings;
    let mode;
    if (isMdScreen) {
      mode = layout1Settings.leftSidebar.mode === 'close' ? 'mobile' : 'close';
    } else {
      mode = layout1Settings.leftSidebar.mode === 'full' ? 'close' : 'full';
    }
    updateSidebarMode({ mode });
  };
  const userw = JSON.parse(localStorage.getItem("user"));
  // console.log(user);

  // if ( user === undefined ||user === null||user.role===null||user.role===undefined||user.role.length===0||user.role[0].toString().includes("ROLE_USER"))
  //   return userNavigations;
  // else if (user.role[0].toString().includes("ROLE_ADMIN"))
  //   return adminNavigations;
  // else if (user.role[0].toString().includes("ROLE_SUPERADMIN"))
  //   return superAdminNavigations;

  const handleFullScreen = () => {
    const element = document.documentElement;
    // console.log(element)
    if (!document.fullscreenElement) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen(); // Safari
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen(); // IE11
      }
    }

    else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen(); // Safari
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen(); // IE11
      }

    }


  }

  // Function to get initials from the user's name
  const getInitials = (name) => {
    if (!name) return '';
    const nameArray = name.trim().split(' ');
    return nameArray.map((word) => word[0]).join('').toUpperCase();
  };

  // Inside your component
  const initials = getInitials(user && user.name);

  return (

    <TopbarRoot style={{ backgroundColor: darkTheme ? '#000' : '#f9f9f9' }}>
      <TopbarContainer marginTop={"5px"} style={{ backgroundColor: darkTheme ? '#000' : '#f9f9f9' }}>
        {/* <Box display="flex" alignItems="center" padding={"10px"}>
         <Grid container alignItems="center">
         <Grid item lg={2} md={2} xl={2} padding={"10px"} display={"flex"} justifyContent={"center"}>
         <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            <img src="/assets/images/evoptHomeLogo.png" alt="EVOPT LOGO" 
              style={{ height: "80%", width: "100%" }} />
          </Link>
          </Grid>
          
          <Grid item  lg={2} md={2} xl={2} display="flex" justifyContent="center">
            <Depot />
          </Grid>

          <Grid item lg={7} md={7} xl={7} display="flex" justifyContent="center">
          <LabTabs />
          </Grid>

         </Grid>
        </Box> */}
        {/* <Box  alignItems="center"  width="100%"> */}
        <Grid container alignItems="center" spacing={1} >
          <Grid item xs={1} sm={3} md={2} lg={2} xl={2}

            sx={{ display: { xs: 'none', sm: 'none', lg: 'flex', md: 'none' },marginTop:1 }}

            // display="flex" justifyContent="center"
            >
            <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            <Grid style={{height:'4rem'}}>
              {
                darkTheme ?
                  <img src="/assets/images/evoptHomeLogoWhite.png" alt="EVOPT LOGO" style={{ maxHeight: "100%", maxWidth: "100%" }} /> :
                  <img src="/assets/images/evoptHomeLogo.png" alt="EVOPT LOGO" style={{ maxHeight: "100%",
                    height: "auto",
                    objectFit: 'contain' ,


                    maxWidth: "100%" }} />
              }
              </Grid>
            </Link>

          </Grid>

          <Grid item xs={2}
            sx={{ display: { xs: 'block', sm: 'block', lg: 'none', md: 'block' },zIndex:'999999' }}
             >
            <MenuIcon onClick={()=>setSideNavOpen(true)} />
          </Grid>

          <Grid item xs={10} sm={6} md={6} lg={3}  >
            <DepotZone />
          </Grid>



          <Grid item lg={6}
            sx={{ display: { xs: 'none', sm: 'none', lg: 'flex', md: 'none' } }}


          // marginLeft="-20px"
          >
            <LabTabs />
          </Grid>
        </Grid>
        {/* </Box> */}

        <Box display="flex" alignItems="center" padding={"10px"}>
          {/* <MatxSearchBox style={{ color: darkTheme ? '#fff' : '#000' }} /> */}


          <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>

            <ThemeSwitch />


            <Tooltip title="Full Screen" sx={{ display: { xs: 'none', sm: 'none', lg: 'flex', md: 'none' } }}>
              <IconButton onClick={handleFullScreen}>
                <FullscreenIcon style={{ color: darkTheme ? '#f2e34c' : '#000' }} />
              </IconButton>
            </Tooltip>



            {/* Spacer for equal spacing */}
            {/* <div style={{ flexGrow: 1 }} /> */}

            {/* Settings Icon */}
            {userw && userw.role && userw.role.length > 0 && !userw.role[0].toString().includes("ROLE_USER") && <Setting />}

            {/* Notification Provider */}
            <div >
              <NotificationProvider>
                <NotificationBar />
              </NotificationProvider>
            </div>

            {/* UserMenu for Avatar */}
            <MatxMenu menuButton={
              <UserMenu >
                <Avatar sx={{ cursor: 'pointer', backgroundColor: darkTheme ? '#f2e34c' : '#000' }}>
                  <Span style={{ color: darkTheme ? '#000' : '#fff' }}>
                    {initials}
                  </Span>
                </Avatar>
              </UserMenu>
            }>
              {/* User's full name */}
              <StyledItem style={{ color: darkTheme ? '#F2E34C' : '#000', backgroundColor: darkTheme ? '#333333' : '#f9f9f9' }}>
                <Span style={{ color: darkTheme ? '#f2e34c' : '#000', fontSize: '16px', textAlign: 'center' }}>
                  <strong>{user && user.name}</strong>
                </Span>
              </StyledItem>

              {/* Links and Logout */}
              <StyledItem style={{ color: darkTheme ? '#F2E34C' : '#000', backgroundColor: darkTheme ? '#333333' : '#f9f9f9' }}>
                <Link to="/">
                  <Icon style={{ color: darkTheme ? '#F2E34C' : '#000' }}> home </Icon>
                  <Span style={{ color: darkTheme ? '#F2E34C' : '#000' }}> Home </Span>
                </Link>
              </StyledItem>
              <StyledItem style={{ color: darkTheme ? '#F2E34C' : '#000', backgroundColor: darkTheme ? '#333333' : '#f9f9f9' }}>
                <Link to="/userprofile/default">
                  <Icon style={{ color: darkTheme ? '#F2E34C' : '#000' }}> person </Icon>
                  <Span style={{ color: darkTheme ? '#F2E34C' : '#000' }}> Profile </Span>
                </Link>
              </StyledItem>

              <StyledItem style={{ color: darkTheme ? '#F2E34C' : '#000', backgroundColor: darkTheme ? '#333333' : '#f9f9f9' }}>
                <PersonOutlineTwoToneIcon style={{ color: darkTheme ? '#f2e34c' : '#000' }} />
                <Span style={{ color: darkTheme ? '#f2e34c' : '#000' }}>My Expert</Span>
              </StyledItem>

              <StyledItem onClick={logout} style={{ color: darkTheme ? '#F2E34C' : '#000', backgroundColor: darkTheme ? '#333333' : '#f9f9f9' }}>
                <Icon style={{ color: darkTheme ? '#F2E34C' : '#000' }}> power_settings_new </Icon>
                <Span style={{ color: darkTheme ? '#F2E34C' : '#000' }}> Logout </Span>
              </StyledItem>






            </MatxMenu>
          </div>
        </Box>


      </TopbarContainer>

    </TopbarRoot>
  );
};

export default React.memo(Layout1Topbar);
