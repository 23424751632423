import { Grid, Paper, Box, Tabs, Tab } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import Battery from '../SLD/Battery'
import SimulatorApi from "app/views/simulation/SimulatorApi";
import { useLocation, useNavigate } from "react-router-dom";
import ChargerTree, { MeterDetailDialog } from "app/views/setdepot/shared/ChargerTree";
import Link from './linknode/Link'
import Sld from '../SLD/Sld.js'
// import { DirectionsBusIcon } from "@mui/icons-material/DirectionBus";
import { useThemeContext } from 'app/ThemeProvider';
import Analytics from "./depot/Analytics";
import MeterApi from "./meter-detail/MeterApi";
import Loading from "app/components/MatxLoading";
import Imagee from "app/views/test/App";


// function CustomTabPanel(props) {
//   const { children, value, index, ...other } = props;
const ChargingBay = () => {


	const navigate = useNavigate();



	useEffect(() => {

		const permissions = JSON.parse(localStorage.getItem("permission"))
			.filter(obj => obj.type === 'navigation')
			.map(obj => obj.name) ?? [];



		if (!permissions.includes("Infra"))
			navigate('/setdepot/default');

	}, []);




	const [APIData, setAPIData] = useState([]);
	const api = new SimulatorApi();
	const meterApi = new MeterApi();

	const search = useLocation().search;

	const sim_type = new URLSearchParams(search).get("sim_type");
	const sel_vehicle = new URLSearchParams(search).get("vehicle");
	const [animateBorder, setAnimateBorder] = useState(false);
	const { darkTheme } = useThemeContext();
	const [meterDataList, setMeterDataList] = useState([]);

	const [loading, setLoading] = useState(false);

	const selectedDepot = localStorage.getItem('selected_depot');

	const handleAnimationStart = () => {
		setAnimateBorder(true);
	};

	const handleAnimationEnd = () => {
		setAnimateBorder(false);
	};

	useEffect(() => {

		setLoading(true)
		if (sim_type === "single_v") {
			showSingleChargerGraph(sel_vehicle);
		} else {
			showChargerStatusGraph();
		}
		const interval = setInterval(() => {
			if (sim_type === "single_v") {
				showSingleChargerGraph(sel_vehicle);
			} else {
				showChargerStatusGraph();
			}
		}, 10000);
		return () => clearInterval(interval);
	}, []);

	const showSingleChargerGraph = (vehicleId) => {
		api
			.showSingleChargerStatusGraph(null, vehicleId)
			.then((response) => {
				setAPIData(response.data);
			})
			.catch((e) => {
				// console.log("Cannot load event data", e);
			});
	};

	const showChargerStatusGraph = () => {
		debugger;
		api
			.showChargerStatusGraph()
			.then((response) => {
				setAPIData(response.data);
				setLoading(false)
			})
			.catch((e) => {
				// console.log("Cannot load event data", e);
			});
	};


	const showMeterByDepotId = (id) => {
		debugger;
		meterApi
			.getEnergyMeterByDepotId(id)
			.then((response) => {
				setMeterDataList(response.data);
				console.log(response)
			})
			.catch((e) => {
				console.log("Cannot load event data", e);
			});
	};

	console.log(meterDataList);

	const [borderColor, setBorderColor] = useState("transparent");
	const currentDepotId = localStorage.getItem("selected_depot");

	useEffect(() => {
		showMeterByDepotId(currentDepotId);
		setBorderColor((prevColor) =>
			prevColor === "transparent" ? "green" : "transparent"
		);
		const intervalId = setInterval(() => {
			setBorderColor((prevColor) =>
				prevColor === "transparent" ? "green" : "transparent"
			);
		}, 10000);
		return () => clearInterval(intervalId);
	}, []);

	// const [value, setValue] = useState(0);
	const [selectedTab, setSelectedTab] = useState(0);

	const handleChange = (event, newValue) => {
		setSelectedTab(newValue);
	};


	const paperStyle = {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		alignItems: "center",
		width: "100%",
		height: "174px",
		background: "#e7e7e7",
		padding: "4px 2px",
		borderRadius: "10px",
		border: `5px solid ${borderColor}`, // Border color changes based on the state
		transition: "border-color 0.5s ease", // CSS transition for the border color change
	};

	const backgroundColorSelected = darkTheme ? '#F2E34C' : 'black'; // Background color of selected tab
	const backgroundColorUnselected = darkTheme ? '#334155' : '#d9d9d9';
	const textColorSelected = darkTheme ? 'black' : 'white'; // Text color of selected tab
	const textColorUnselected = darkTheme ? 'white' : 'black';

	return (
		<Paper width={"100%"} sx={{ backgroundColor: darkTheme ? '#333333' : 'white' }}
		// style={{ padding: "10px", margin: "0", overflowX: "auto" }}
		// elevation={3} alignItems={"center"} justifyContent={"center"}
		>
			<Grid container direction={'row'} style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9' }}>

				{/* <Grid item md={2} container>

				</Grid> */}


				<Grid item md={8} paddingLeft={"5px"}>
					<Grid container padding={"2px"} spacing={2}  >

						{
							selectedTab === 0 ? meterDataList.map(meter => {
								return <Grid item md={1.5}>
									{/* <Paper style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} elevation={3}> */}
									<MeterDetailDialog element={{ meter_id: meter.meterId, meter_pk: meter.id }} />
									<Grid container alignItems="center" justifyContent="center" style={{ width: "100%", padding: 0 }}>
										<b>{meter.deviceId}</b>

									</Grid>
									{/* </Paper> */}
								</Grid>
							}) : ""
						}
						{/* <Grid item md={2}>
							<Paper style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} elevation={3}>
								<MeterDetailDialog />
							</Paper>
						</Grid>
						<Grid item md={2}>
							<Paper style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} elevation={3}>
								<MeterDetailDialog />
							</Paper>
						</Grid>
						<Grid item md={2}>
							<Paper style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} elevation={3}>
								<MeterDetailDialog />
							</Paper>
						</Grid>
						<Grid item md={2}>
							<Paper style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} elevation={3}>
								<MeterDetailDialog />
							</Paper>
						</Grid> */}
					</Grid>
				</Grid>


				<Grid item xs={4}>
					<Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', marginRight: '5%', padding: 0 }}>
						<Tabs value={selectedTab}
							onChange={handleChange}
							textColor="black"
							style={{ padding: 0, backgroundColor: darkTheme ? '#f2e34c' : 'lightgrey', borderRadius: 15 }}
							aria-label="secondary tabs example"

						>
							<Tab
								style={{ backgroundColor: selectedTab === 0 ? backgroundColorSelected : backgroundColorUnselected, color: selectedTab === 0 ? textColorSelected : textColorUnselected }}
								label="HLD" />
							{
								selectedDepot == 92 ?
									<Tab
										style={{ backgroundColor: selectedTab === 1 ? backgroundColorSelected : backgroundColorUnselected, color: selectedTab === 1 ? textColorSelected : textColorUnselected }}
										label="Network Layout" /> : ""
							}

							<Tab
								style={{ backgroundColor: selectedTab === 2 ? backgroundColorSelected : backgroundColorUnselected, color: selectedTab === 2 ? textColorSelected : textColorUnselected }}
								label="Depot" />
							<Tab
								style={{ backgroundColor: selectedTab === 3 ? backgroundColorSelected : backgroundColorUnselected, color: selectedTab === 3 ? textColorSelected : textColorUnselected }}
								label="SLD" />


						</Tabs>
					</Box>
				</Grid>
				<Grid item xs={12} padding={"10px"} sx={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9' }}>
					{selectedTab === 3 ?
						<Grid item container spacing={1} sx={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9' }}>

							{
								APIData && APIData.map((bay, index) => {
									return bay.map((element, index) => {
										return <Grid item md={1.5}><Battery element={element} /></Grid>
									})
								})
							}

							{/* <div><Battery chargeBoxId='GEXHYDSCK00042' /></div>
							<div ><Battery chargeBoxId='GEXHYDSCK00043' /></div>
							<div><Battery chargeBoxId='GEXHYDSCK00044' /></div>
							<div><Battery chargeBoxId='GEXHYDSCK00045' /></div> */}
						</Grid> : null}
				</Grid>


			</Grid>

			{selectedTab === 0 ?
				loading ? <Paper sx={{
					width: '100%', overflow: 'hidden', height: '60vh'
					, backgroundColor: darkTheme ? '#333333' : '#f9f9f9'
				}} ><Loading /></Paper> :
					APIData.map((bay) => (
						<Grid
							container
							// spacing={2}
							width={"100%"}
							alignItems={"center"} justifyContent={"center"}
							sx={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9' }}
						// wrap="nowrap"
						>
							<ChargerTree bay={bay} />
						</Grid>
					)) : null}

			{selectedTab === 1 && parseInt(currentDepotId) === 92 ? < Imagee /> : null}


			{selectedTab === 2 && parseInt(currentDepotId) === 92 ? <Analytics /> : null}

			{selectedTab === 3 ?
				<Sld />
				: null}

		</Paper>
	);
};

export default ChargingBay;